.add-to-blacklist-form{
    .MuiFormHelperText-root{
        position: absolute;
        bottom: -20px;
        margin: 0;
    }

    .MuiFormControl-root{
        margin: 0 0 24px 0;
    }

    .MuiListItemText-dense{
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
