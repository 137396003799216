.broadcasters-list{
    .table-row{
        td:not(:last-child){
            opacity: 1;
            transition: 0.3s;
        }
        
        &.unactive td:not(:last-child){
            opacity: 0.4;
        }
        &:last-child td, &:last-child th{
            border: none;
        }
    }
}